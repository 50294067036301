/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
export const optionSwiftMe = (t) => [
    {
        aclCode: 'swiftme_pages',
        key: 'income',
        label: t('menu:Income'),
        url: '/seller/income',
    },
    {
        aclCode: 'swiftme_pages',
        key: 'swiftpage',
        label: 'Swift Page',
        children: [{
            aclCode: 'swiftme_pages',
            key: 'managepage',
            label: t('menu:Manage_Page'),
            url: '/seller/swiftpage/managepage',
        }],
    },
    {
        aclCode: 'swiftme_ads',
        key: 'swiftads',
        label: 'Swift Ads',
        children: [
            {
                aclCode: 'swiftme_ads',
                key: 'manageads',
                label: t('menu:ManageAds'),
                url: '/seller/adscampaign',
            },
            {
                aclCode: 'swiftme_ads',
                key: 'adsperformance',
                label: t('menu:AdsPerformance'),
                url: '/seller/adsperformance',
            },
            {
                aclCode: 'swiftme_ads',
                key: 'rfm',
                label: t('menu:RFM'),
                url: '/seller/rfm',
            },
            {
                aclCode: 'swiftme_ads',
                key: 'adscampaign',
                label: t('menu:Configuration'),
                url: '/seller/adscampaign/configuration',
            },
        ],
    },
    {
        aclCode: 'swiftme_loyalty',
        key: 'loyalty',
        label: 'Swift Loyalty',
        children: [
            {
                aclCode: 'swiftme_loyalty',
                key: 'loyalty_configuration',
                label: t('menu:Configuration'),
                url: '/seller/loyalty/configuration',
            },
            {
                aclCode: 'swiftme_loyalty',
                key: 'loyalty_customer',
                label: t('menu:Customers'),
                url: '/seller/loyalty/customers',
            },
            {
                aclCode: 'swiftme_loyalty',
                key: 'loyalty_transaction',
                label: t('menu:Transactions'),
                url: '/seller/loyalty/transactions',
            },
        ],
    },
];
