/* eslint-disable */
import getConfig from 'next/config';
import Cookies from 'js-cookie';

import { isValidJSON } from '@helper_text';
import { decrypt, encrypt } from '@helper_encryption';
import firebase from './index';

const { publicRuntimeConfig } = getConfig();
const crypto = require('crypto');

const notification = {
    async getSecretKey() {
        try {
            const webInitCookie = Cookies.get('web_init');
            if (webInitCookie) {
                const webInit = decrypt(webInitCookie);
                const webInitData = isValidJSON(webInit) ? JSON.parse(webInit) : {};
                const companyId = String(webInitData?.company_id);

                if (companyId) {
                    const fetchSecretKey = await fetch('/get-secret-key', { headers: { 'Content-Type': 'application/json' } });
                    const secretKey = await fetchSecretKey.json();
                    return secretKey?.secret_key ? decrypt(secretKey?.secret_key) : false;
                }
            }
        } catch (error) {}

        return false;
    },
    async getToken() {
        try {
            const secretKey = await notification.getSecretKey();
            const webInitCookie = Cookies.get('web_init');

            if (secretKey && webInitCookie) {
                const webInit = decrypt(webInitCookie);
                const webInitData = isValidJSON(webInit) ? JSON.parse(webInit) : {};
                const companyId = String(webInitData?.company_id);

                if (companyId) {
                    const signature = crypto.createHmac('sha256', secretKey).update(companyId).digest('base64');
                    const timestamp = (new Date).getTime();
                    const ecryptedCompany = encrypt(JSON.stringify({companyId, timestamp}));
                    const ecryptedSignature = encrypt(JSON.stringify({signature, timestamp}));
                    const fetchSecretKey = await fetch('/get-custom-token', {
                        headers: { 'Content-Type': 'application/json' },
                        method: 'post',
                        body: JSON.stringify({
                            companyId: ecryptedCompany,
                            signature: ecryptedSignature,
                        }),
                    });

                    const token = await fetchSecretKey.json();
                    return token?.token ? decrypt(token?.token) : false;
                }
            }
        } catch (error) { }

        return false;
    },
    async updateTokenToFirebase() {
        try {
            const customToken = await notification.getToken();
            if (customToken) {
                const auth = firebase.auth().signInWithCustomToken(customToken);
                return auth.user;
            }
        } catch (error) { }

        return false;
    },
    async getFcmToken() {
        try {
            const secretKey = await notification.getSecretKey();
            const webInitCookie = Cookies.get('web_init');
            const pairKey = String(publicRuntimeConfig.FCM_PAIR_KEY);

            if (secretKey && webInitCookie) {
                const webInit = decrypt(webInitCookie);
                const webInitData = isValidJSON(webInit) ? JSON.parse(webInit) : {};
                const companyId = String(webInitData?.company_id);
                const messaging = firebase.messaging();
                const fcmToken = await messaging.getToken({ vapidKey: pairKey });

                if (companyId && fcmToken) {
                    const combinedKey = `${companyId}:${fcmToken}`;
                    const signature = crypto.createHmac('sha256', secretKey).update(combinedKey).digest('base64');
                    const timestamp = (new Date).getTime();
                    const ecryptedCompany = encrypt(JSON.stringify({companyId, timestamp}));
                    const ecryptedSignature = encrypt(JSON.stringify({signature, timestamp}));
                    const ecryptedFcmToken = encrypt(JSON.stringify({fcmToken, timestamp}));
                    await fetch('/set-custom-token', {
                        headers: { 'Content-Type': 'application/json' },
                        method: 'post',
                        body: JSON.stringify({
                            companyId: ecryptedCompany,
                            fcmToken: ecryptedFcmToken,
                            signature: ecryptedSignature,
                        }),
                    });
                    return true;
                }
            }
        } catch (error) { }

        return false;
    },
    init() {
        notification.updateTokenToFirebase();
        notification.getFcmToken();
    },
};

export default notification;
